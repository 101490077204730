import React from "react"
import { Link, useIntl } from "gatsby-plugin-intl"
import style from "./style.module.css"
import { useSiteMetadata } from "../../hooks"

const Footer = () => {
  const intl = useIntl()
  const site = useSiteMetadata()
  const logo = `/images/${site.foot_logo}`

  const copyright = site.copy_right_date
    ? `Copyright © 2014-${new Date().getFullYear()}`
    : ""
  const companyName = site.copy_right_company ? site.copy_right_company : ""
  const siteCaseNumber = site.site_case_number ? site.site_case_number : ""

  return (
    <footer className={style.footer}>
      <div className={style.content}>
        <nav className={style.nav}>
          <img src={logo} alt="logo" />
          <div className={style.link}>
            <Link to="/" className={style.link_nav}>
              {intl.formatMessage({ id: "产品服务" })}
            </Link>
            {/*<Link to="/download" className={style.link_nav}>*/}
            {/*  {intl.formatMessage({ id: "下载中心" })}*/}
            {/*</Link>*/}
            <Link to="/contact" className={style.link_nav}>
              {intl.formatMessage({ id: "联系销售" })}
            </Link>
          </div>
        </nav>

        <div className={style.copyright}>
          {copyright} {companyName}. All Rights Reserved. 备案号：
          <a target="_blank" href="https://beian.miit.gov.cn">
            {siteCaseNumber}
          </a>
        </div>
      </div>
    </footer>
  )
}

export default Footer

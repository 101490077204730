import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { useIntl, IntlContextConsumer, changeLocale  } from 'gatsby-plugin-intl';
import style from './style.module.css';

const ChangeLocale = (props) => {
  const { className } = props;
  const wrapCls = classnames(style.container, className);
  const intl = useIntl()

  const locale2Label = {
    'zh': '简体中文',
    'zh-tw': intl.formatMessage({ id: '繁体中文' })
  }

  const handleChangeLocale = (e) => {
    const locale = e.target.value;
    changeLocale(locale)
    console.log(e.target.value)
  }

  return (
    <div className={wrapCls}>
      <IntlContextConsumer>
        {({ languages, language: currentLocale }) => (
          <select
            name="locale"
            required
            value={currentLocale}
            onChange={handleChangeLocale}
          >
            {languages.map(lan => (
              <option value={lan} key={lan}>
                {locale2Label[lan]}
              </option>
            ))}
          </select>
        )}
      </IntlContextConsumer>
    </div>
  );
}

ChangeLocale.defaultProps = {
  className: ''
};

ChangeLocale.propTypes = {
  className: PropTypes.string,
};

export default ChangeLocale;

/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useSiteMetadata } from '../../hooks';

import Header from '../Header';
import Footer from '../Footer';
import Tools from '../Tools';
import style from './index.module.css';

const Layout = ({ children, activeNav }) => {
  const site = useSiteMetadata()

  return (
    <>
      <Header
        siteTitle={site.title}
        activeNav={activeNav}
      />
      <main className={style.main}>{children}</main>
      <Footer />

      <Tools className={style.tools} />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  activeNav: PropTypes.string,
}

export default Layout

/*
 * @Author: fan.lion@foxmail.com 
 * @Date: 2019-09-16 21:22:28 
 * @Last Modified by: fan.lion@foxmail.com
 * @Last Modified time: 2019-09-24 21:34:59
 * 
 * 站点固定工具
 */

import React from 'react';
import style from './index.module.css';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'gatsby-plugin-intl'
import { useSiteMetadata } from '../../hooks'
import ToolMessageIcon from '../../images/tool_message_icon@2x.png';
import ToolPhoneIcon from '../../images/tool_phone_icon@2x.png';
import ToolTopIcon from '../../images/tool_top_icon@2x.png';

const Tools = ({ className }) => {
  const wrapCls = classnames(style.container, className);
  const site = useSiteMetadata()

  return (
    <div className={wrapCls}>
      <ul>
        <li>
          <a href="#">
            <img src={ToolTopIcon} alt="back top" />
          </a>
        </li>
        {!!site.bd_phone && (
          <li>
            <img src={ToolPhoneIcon} alt="contact phone" />
              <div className={style.dropdown_content}>
                <a href={`tel:${site.bd_phone}`} className={style.phone}>
                  {site.bd_phone}
                </a>
              </div>
          </li>
        )}
        <li>
          <Link to="/contact">
            <img src={ToolMessageIcon} alt="contact with message" />
          </Link>
        </li>
      </ul>
    </div>
  );
};

Tools.defaultProps = {
  className: '',
};

Tools.propTypes = {
  className: PropTypes.string, // className
};

export default Tools;

import React from "react"
import PropTypes from "prop-types"
import { useIntl, Link } from "gatsby-plugin-intl"
import classnames from "classnames"
import Headroom from "react-headroom"
import ChangeLocale from "../../components/ChangeLocale"
import { useSiteMetadata } from "../../hooks"
import style from "./style.module.css"

const Header = ({ activeNav }) => {
  const intl = useIntl()
  const site = useSiteMetadata()
  const logo = `/images/${site.head_logo}`
  const supportTrail = site.support_trail === "true" // 是否支持免费试用
  const supportConsole = site.support_console === "true" // 是否支持控制台

  return (
    <Headroom>
      <header className={style.header}>
        <div className={style.content}>
          <section className={style.left}>
            <Link to="/" className={style.link}>
              <img className={style.logo} src={logo} alt={`${site.name}logo`} />
            </Link>
            <nav className={style.nav}>
              <Link to="/" className={classnames(style.link, style.link_nav)}>
                {intl.formatMessage({
                  id: "产品服务",
                })}
                {activeNav === "service" && (
                  <span className={style.link_underline}></span>
                )}
              </Link>

              {/*<Link*/}
              {/*  to="/download"*/}
              {/*  className={classnames(style.link, style.link_nav)}*/}
              {/*>*/}
              {/*  {intl.formatMessage({*/}
              {/*    id: "下载中心",*/}
              {/*  })}*/}
              {/*  {activeNav === "download" && (*/}
              {/*    <span className={style.link_underline}></span>*/}
              {/*  )}*/}
              {/*</Link>*/}

              <Link
                to="/contact"
                className={classnames(style.link, style.link_nav)}
              >
                {intl.formatMessage({
                  id: "联系销售",
                })}
                {activeNav === "contact" && (
                  <span className={style.link_underline}></span>
                )}
              </Link>
            </nav>
          </section>

          <section className={style.right}>
            {supportConsole && (
              <a
                href="https://console.teampro.work/#/login?redirect=%2Fmeeting"
                target="_blank"
                rel="noopener noreferrer"
                className={style.login_btn}
              >
                {intl.formatMessage({
                  id: "登录",
                })}
              </a>
            )}

            {supportTrail && (
              <a
                role="button"
                href="https://console.teampro.work/#/register"
                target="_blank"
                rel="noopener noreferrer"
                className={style.free_trail}
              >
                {intl.formatMessage({
                  id: "免费试用",
                })}
              </a>
            )}

            <ChangeLocale className={style.locale} />
          </section>
        </div>
      </header>
    </Headroom>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
  activeNav: PropTypes.string, // 当前选中的菜单
}

Header.defaultProps = {
  siteTitle: "",
  activeNav: "service",
}

export default Header
